import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { SidebarMultilevel } from '@datum/react-components';
import { NavLink } from 'react-router-dom';

import { sections } from 'config/sections';
import { NavLinkItem } from 'typings';
import { useUserData } from 'utils/hooks/useUserData';
import { useCanViewProfiles } from 'utils/hooks/useCanViewPofiles';
import { useCanViewArchive } from 'utils/hooks/useCanViewArchive';

type NavLinkTreeItem = NavLinkItem & { children?: NavLinkTreeItem[] };

function mountSectionTree(tree: NavLinkTreeItem[], _sections: NavLinkTreeItem[]) {
  return tree.map((_treeItem) => {
    let _children = _sections.filter((_section) => _section.parentId === _treeItem.key);
    if (_children.length) {
      _children = mountSectionTree(_children, _sections);
      _treeItem.children = _children;
    }
    return _treeItem;
  });
}

function TreeItem({ item }: { item: NavLinkTreeItem }): JSX.Element {
  if (item.route !== '') {
    return (
      <SidebarMultilevel.Item icon={item.icon} label={item.label} as={NavLink} to={item.route} color={item.color} />
    );
  }
  return (
    <SidebarMultilevel.Group label={item.label} icon={item.icon} color={item.color}>
      {item.children?.map((_item) => (
        <TreeItem key={_item.key} item={_item} />
      ))}
    </SidebarMultilevel.Group>
  );
}

function AppSidebar(): JSX.Element {
  const { t } = useTranslation();
  const { isAdmin, isUserRole } = useUserData();
  const canViewProfiles = useCanViewProfiles();
  const canViewArchive = useCanViewArchive();
  const allowedSectionsTree = React.useMemo(() => {
    const _sections = [...sections]
      .filter((s) => {
        if (!isAdmin && s.key === 'adminPanel') return false;
        if (isUserRole && s.key === 'userProfileReports') return false;
        if (isUserRole && s.key === 'profile') return false;
        if (!canViewProfiles && s.key === 'profile') return false;
        if (isUserRole && s.key === 'help') return false;
        if (!canViewArchive && s.key === 'archive') return false;
        if (!canViewArchive && s.key === 'claims') return false;
        return true;
      })
      .map((_sec) => {
        _sec.label = t(_sec.label);
        return _sec;
      });
    const _tree: NavLinkTreeItem[] = [];
    _sections.forEach((_section) => {
      if (!_section.parentId) {
        _tree.push(_section);
      }
    });
    return mountSectionTree(_tree, _sections);
  }, [t, isAdmin]);

  return (
    <SidebarMultilevel aria-label={t('sidebarMenu')}>
      {allowedSectionsTree.map((linkItem) => (
        <TreeItem key={linkItem.key} item={linkItem} />
      ))}
    </SidebarMultilevel>
  );
}

export { AppSidebar };
