import { TFunction } from 'i18next';
import * as yup from 'yup';
import { DENTAL_FIELDS } from './fields';
import { test100YearsPast, testNPIforLuhnAlghoritm } from './customRules';
import { testMoreThan1Year } from 'utils/ub04/customRules';

export const getDentalWarningSchema = (t: TFunction) =>
  yup.object().shape({
    [DENTAL_FIELDS.subscriberDateOfBirth]: yup
      .string()
      .nullable()
      .test('custom-dos-fromPast', t('common:fieldErrors.DATE004'), test100YearsPast),
    [DENTAL_FIELDS.subDateOfBirth]: yup
      .string()
      .nullable()
      .test('custom-dos-fromPast', t('common:fieldErrors.DATE004'), test100YearsPast),
    [DENTAL_FIELDS.patDateOfBirth]: yup
      .string()
      .nullable()
      .test('custom-dos-fromPast', t('common:fieldErrors.DATE004'), test100YearsPast),
    [DENTAL_FIELDS.firstVisitDate]: yup
      .string()
      .nullable()
      .test('custom-dos-fromPast', t('common:fieldErrors.DOS004'), testMoreThan1Year),
    [DENTAL_FIELDS.subSignatureDate]: yup
      .string()
      .nullable()
      .test('custom-dos-fromPast', t('common:fieldErrors.DATE004'), test100YearsPast),
    [DENTAL_FIELDS.patGuardSignatureDate]: yup
      .string()
      .nullable()
      .test('custom-dos-fromPast', t('common:fieldErrors.DATE004'), test100YearsPast),
    [DENTAL_FIELDS.appPlaceDate]: yup
      .string()
      .nullable()
      .test('custom-dos-fromPast', t('common:fieldErrors.DATE004'), test100YearsPast),
    [DENTAL_FIELDS.priorPlacement]: yup
      .string()
      .nullable()
      .test('custom-dos-fromPast', t('common:fieldErrors.DATE004'), test100YearsPast),
    [DENTAL_FIELDS.accDate]: yup
      .string()
      .nullable()
      .test('custom-dos-fromPast', t('common:fieldErrors.DATE004'), test100YearsPast),
    [DENTAL_FIELDS.treatSignDate]: yup
      .string()
      .nullable()
      .test('custom-dos-fromPast', t('common:fieldErrors.DATE004'), test100YearsPast),
    [DENTAL_FIELDS.procedureDate]: yup
      .string()
      .nullable()
      .test('custom-dos-fromPast', t('common:fieldErrors.DATE004'), test100YearsPast),
    [DENTAL_FIELDS.billingNpi]: yup.string().nullable(),
    //.test('custom-renderNPI24', 'NPI is not valid', testNPIforLuhnAlghoritm),
    [DENTAL_FIELDS.treatNpi]: yup.string().nullable()
    //.test('custom-treatNpi', 'NPI is not valid', testNPIforLuhnAlghoritm)
  });

export const knownWarnings = [
  DENTAL_FIELDS.subscriberDateOfBirth,
  DENTAL_FIELDS.subDateOfBirth,
  DENTAL_FIELDS.patDateOfBirth,
  DENTAL_FIELDS.firstVisitDate,
  DENTAL_FIELDS.subSignatureDate,
  DENTAL_FIELDS.patGuardSignatureDate,
  DENTAL_FIELDS.appPlaceDate,
  DENTAL_FIELDS.priorPlacement,
  DENTAL_FIELDS.accDate,
  DENTAL_FIELDS.treatSignDate,
  DENTAL_FIELDS.procedureDate,
  DENTAL_FIELDS.billingNpi,
  DENTAL_FIELDS.treatNpi
];
