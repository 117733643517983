import React, { useState, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import type { ColumnsType } from 'antd/es/table';
import { Form, Input, Modal, Table } from 'antd';
import { DeleteOutlined, InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, IconButton } from '@datum/react-components';

import { DATE_FORMAT } from 'utils/dentalConstants';
import { FormContext } from 'components/common/FormProvider';
import { toCurrency, toCurrencyPaste } from 'helpers/toCurrency';
import { getYupValidator, getYupValidatorServiceTable } from 'utils/dental/validations';
import { DENTAL_FIELDS } from 'utils/dental/fields';
import { toAlphaNumUppercase } from 'helpers/toAlphaNumUppercase';
import DatePickerAutoPopulate from 'components/common/DatePickerAutoPopulate';
import TableTotals from './TableTotals';
// import { toCurrencyNegative } from 'helpers/toCurrencyWithNegative';

type PaperServicesListProps = {
  readOnly: boolean;
};

const PER_PAGE = 6;

function PaperServicesList({ readOnly }: PaperServicesListProps): JSX.Element {
  const { t } = useTranslation();
  const { form } = useContext(FormContext);
  const [currentPage, setCurrentPage] = useState(1);
  const total = useRef(0);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalRow, setModalRow] = useState<number>();

  const showModal = (open: boolean) => {
    setIsModalOpen(open);
  };

  const onAdd = (add: (defaultValue?: any, insertIndex?: number | undefined) => void) => {
    const totalCount = total.current;
    if (totalCount % PER_PAGE === 0) {
      setCurrentPage(totalCount / PER_PAGE + 1);
    }
    add();

    const tableValues = form?.getFieldValue(['24Table']);

    if (tableValues && tableValues.length) {
      const idx = tableValues.length - 1;
      setTimeout(() => {
        const inputEl = document.getElementById(`${DENTAL_FIELDS.procedureDate}__${idx}`);
        if (inputEl) inputEl.focus();
      }, 10);
    }
  };

  const columns: ColumnsType<any> = [
    {
      title: `S.No`,
      key: DENTAL_FIELDS.serialNumber,
      render(_v, _r, _i) {
        return _v.name + 1;
      }
    },
    {
      title: `24. ${t('common:fields.procedureDate')}`,
      key: DENTAL_FIELDS.procedureDate,
      render: (field) => (
        <Form.Item
          label={'Procedure Date'}
          name={[field.name, DENTAL_FIELDS.procedureDate]}
          rules={[getYupValidatorServiceTable(t, DENTAL_FIELDS.procedureDate)]}
        >
          <DatePickerAutoPopulate
            inputId={`${DENTAL_FIELDS.procedureDate}__${field.name}`}
            suffixIcon={null}
            placeholder={t('common:fields.dateFormat')}
            panelRender={() => false}
            format={DATE_FORMAT}
            // @ts-ignore
            name={[field.name, DENTAL_FIELDS.procedureDate]}
            form={form!}
            tableName="24Table"
          />
        </Form.Item>
      )
    },
    {
      title: `25. ${t('common:fields.areaOfOralCavity')}`,
      key: DENTAL_FIELDS.cavityArea,
      render: (field) => (
        <Form.Item
          label={`Area of Oral Cavity`}
          name={[field.name, DENTAL_FIELDS.cavityArea]}
          rules={[getYupValidatorServiceTable(t, DENTAL_FIELDS.cavityArea)]}
        >
          <Input placeholder="00" onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
        </Form.Item>
      )
    },
    {
      title: `26. ${t('common:fields.toothSystem')}`,
      key: DENTAL_FIELDS.toothSystem,
      render: (field) => (
        <Form.Item
          label={`Tooth System`}
          name={[field.name, DENTAL_FIELDS.toothSystem]}
          rules={[getYupValidatorServiceTable(t, DENTAL_FIELDS.toothSystem)]}
        >
          <Input placeholder="JP" onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
        </Form.Item>
      )
    },
    {
      title: `27. ${t('common:fields.toothNumbersOrLetters')}`,
      key: DENTAL_FIELDS.toothNumOrLetters,
      render: (field) => (
        <Form.Item
          label={`Tooth Numbers or Letters`}
          name={[field.name, DENTAL_FIELDS.toothNumOrLetters]}
          rules={[getYupValidatorServiceTable(t, DENTAL_FIELDS.toothNumOrLetters)]}
        >
          <Input onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
        </Form.Item>
      )
    },
    {
      title: `28. ${t('common:fields.toothSurface')}`,
      key: DENTAL_FIELDS.toothSurface,
      render: (field) => (
        <Form.Item
          label={`Tooth Surface`}
          name={[field.name, DENTAL_FIELDS.toothSurface]}
          rules={[getYupValidatorServiceTable(t, DENTAL_FIELDS.toothSurface)]}
        >
          <Input onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
        </Form.Item>
      )
    },
    {
      title: `29. ${t('common:fields.procedureCode')}`,
      key: DENTAL_FIELDS.procedureCode,
      render: (field) => (
        <Form.Item
          label={`Code procedure`}
          name={[field.name, DENTAL_FIELDS.procedureCode]}
          rules={[getYupValidatorServiceTable(t, DENTAL_FIELDS.procedureCode)]}
        >
          <Input onInput={toAlphaNumUppercase} placeholder="D0120" />
        </Form.Item>
      )
    },
    {
      title: `29a. ${t('common:fields.diagPointer')}`,
      key: DENTAL_FIELDS.diagPointer,
      render: (field) => (
        <Form.Item
          label={`Diag. Pointer`}
          name={[field.name, DENTAL_FIELDS.diagPointer]}
          rules={[getYupValidatorServiceTable(t, DENTAL_FIELDS.diagPointer)]}
        >
          <Input placeholder="" onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
        </Form.Item>
      )
    },
    {
      title: `29b. ${t('common:fields.quantity')}`,
      key: DENTAL_FIELDS.qty,
      render: (field) => (
        <Form.Item
          label={`Qty.`}
          name={[field.name, DENTAL_FIELDS.qty]}
          rules={[getYupValidatorServiceTable(t, DENTAL_FIELDS.qty)]}
        >
          <Input placeholder="1" />
        </Form.Item>
      )
    },
    {
      title: `30. ${t('common:description')}`,
      key: DENTAL_FIELDS.desc,
      render: (field) => (
        <Form.Item
          label={`Description`}
          name={[field.name, DENTAL_FIELDS.desc]}
          rules={[getYupValidatorServiceTable(t, DENTAL_FIELDS.desc)]}
        >
          <Input
            placeholder="Periodic Oral Eval. - Est. Pt."
            onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())}
          />
        </Form.Item>
      )
    },
    {
      title: `31. ${t('common:fields.fee')}`,
      key: '31_fee',
      render: (field) => (
        <>
          <Form.Item
            label={`Fee`}
            name={[field.name, DENTAL_FIELDS.fee]}
            rules={[getYupValidatorServiceTable(t, DENTAL_FIELDS.fee)]}
          >
            <Input allowClear onPaste={toCurrencyPaste} onInput={toCurrency} placeholder="10.00" prefix="$" />
          </Form.Item>
        </>
      )
    },
    {
      title: ``,
      key: 'actions',
      fixed: 'left',
      render: (field, _, idx) => (
        <>
          <Modal
            style={{ top: -20 }}
            open={isModalOpen}
            onOk={() => {
              field.remove(modalRow);
              showModal(false);
            }}
            onCancel={() => showModal(false)}
            centered
            closable={false}
          >
            <div>
              <InfoCircleOutlined style={{ color: '#faad14' }} />
              {' ' + t('hcfa:form.confirmMessage.deleteService')}
            </div>
          </Modal>
          <IconButton
            className="icon-btn icon-btn--primary-outline icon-btn--md about-button"
            // onClick={() => {
            //   field.remove(idx);
            // }}
            onClick={() => {
              setModalRow(field.name);
              showModal(true);
            }}
            variant="outline"
            icon={<DeleteOutlined />}
            aria-label={t('delete')}
            type="button"
          />
        </>
      )
    }
  ];

  return (
    <>
      <Form.List name="24Table">
        {(fields, { add, remove }) => {
          total.current = fields.length;
          return (
            <>
              <Table
                dataSource={fields.map((field) => ({ ...field, remove }))}
                columns={columns.map((col: any, index) => ({
                  ...col,
                  title: <span className="form_label_color">{col.title}</span>
                }))}
                pagination={{
                  defaultPageSize: PER_PAGE,
                  current: currentPage,
                  onChange: (pg) => {
                    setCurrentPage(pg);
                  }
                }}
                bordered
                //scroll={{ x: true }}
              />
              <div style={{ display: 'flex', flexDirection: 'column' }}></div>
              <Button
                type="button"
                onClick={() => onAdd(add)}
                variant="primary"
                style={{ margin: '10px 5px 5px 5px' }}
                leftIcon={<PlusOutlined />}
                disabled={readOnly}
              >
                {t('hcfa:form.addService')}
              </Button>
            </>
          );
        }}
      </Form.List>
      <>
        <div className="flex mt-1" style={{ display: 'block', maxWidth: '200px', marginLeft: 'auto' }}>
          <Form.Item
            label="31A. Other Fee(s)"
            name={DENTAL_FIELDS.otherFee1}
            rules={[getYupValidator(t, DENTAL_FIELDS.otherFee1)]}
          >
            <Input prefix={'$'} onInput={toCurrency} allowClear />
          </Form.Item>
          <Form.Item
            label="31B. Other Fee(s)"
            name={DENTAL_FIELDS.otherFee2}
            rules={[getYupValidator(t, DENTAL_FIELDS.otherFee2)]}
          >
            <Input prefix={'$'} allowClear onInput={toCurrency} />
          </Form.Item>
          <TableTotals />
          <Form.Item
            style={{ display: 'none' }}
            label={<span className="form_label_color">{t('common:fields.totalCharge')}</span>}
            name={DENTAL_FIELDS.totalFee}
            rules={[getYupValidator(t, DENTAL_FIELDS.totalFee)]}
          >
            <Input prefix={'$'} />
          </Form.Item>
        </div>
      </>
    </>
  );
}

export default PaperServicesList;
